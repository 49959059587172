<template>
  <span>{{ tweeningValue }}</span>
</template>

<script>
import TWEEN from '@tweenjs/tween.js'
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      tweeningValue: 0
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.tween(oldValue, newValue)
    }
  },
  mounted() {
    this.tween(0, this.value)
  },
  methods: {
    tween(startValue, endValue) {
      let animate = () => {
        if (TWEEN.update()) {
          requestAnimationFrame(animate)
        }
      }

      new TWEEN.Tween({ tweeningValue: startValue })
        .to({ tweeningValue: endValue }, 500)
        .onUpdate(object => {
          this.tweeningValue = object.tweeningValue.toFixed(0)
        })
        .start()

      animate()
    }
  }
}
</script>

